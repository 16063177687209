<template>
  <v-contianer>
    <v-row justify="center" class="mt-5 register-form" >
    <v-col cols="12" v-if="user.email">
      <UserProfile :user="user" />
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4" xl="4" v-else>
        <v-card class="pa-5 elevation-2" flat>
          <v-form ref="form" v-model="valid" lazy-validation>
            <h1 class="font-weight-medium my-4">Register</h1>

            <v-text-field
              v-model="formData.name"
              :counter="10"
              :rules="nameRules"
              label="Name"
              required
              outlined
              large
              append-icon="mdi-account-box"
            ></v-text-field>

            <v-text-field
              v-model="formData.email"
              :rules="emailRules"
              label="E-mail"
              required
              outlined
              large
              append-icon="mdi-email"
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              :rules="formData.phoneRules"
              label="Phone No."
              hint="With country Code: +91 1234567891"
              required
              outlined
              large
              append-icon="mdi-phone"
            ></v-text-field>
            <v-text-field
              :rules="passwordRules"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              hint="At least 8 characters"
              v-model="formData.password"
              required
              outlined
              large
              class="input-group--focused"
              @click:append="show1 = !show1"
            ></v-text-field>

            <!-- <v-text-field
              :rules="passwordConfirmRules"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              label="Confirm Password"
              hint="Password should match exactly"
              v-model="formData.confirmPassword"
              required
              outlined
              large
              class="input-group--focused"
              @click:append="show2 = !show2"
            ></v-text-field> -->

            <v-autocomplete
              ref="country"
              v-model="formData.country"
              :rules="[() => !!country || 'Country is required']"
              :items="countries"
              label="Country"
              placeholder="Select..."
              required
              outlined
              append-icon="mdi-map-marker"
              large
            ></v-autocomplete>

            <v-checkbox
              v-model="formData.checkbox"
              label="Join Our Newsletter list"
              color="success"
              required
            ></v-checkbox>

            <v-btn
              class="black white--text mr-4"
              large
              depressed
              :loading="loading"
              @click.prevent="createUser()"
              block
            >
              Register
            </v-btn>
          </v-form>
          <!----------start of the google signup button--->
          <v-btn
            block
            class="mt-2"
            type="submit"
            color="red darken-4 white--text"
            large
            depressed
            @click.prevent="signInWithGoogle()"
          >
            <v-icon left dark>
              mdi-google
            </v-icon>
            Sign Up with Google
          </v-btn>
          <!----------end of the google signup button--->
          <v-alert :color="color" dense text v-if="errorMessage" class="mt-2">
            {{ errorMessage }}
          </v-alert>
          <p class="mt-6">
            Have an Account? Click here to
            <router-link
              to="/login"
              class="font-weight-bold"
              style="text-decoration: none;"
            >
              Login
            </router-link>
          </p>
        </v-card>
      </v-col>
      
    </v-row>
  </v-contianer>
</template>
<script>
import { auth, db } from "@/utils/firebase";
import UserProfile from "@/components/UserProfile";
import firebase from "firebase/app";
import { mapGetters } from "vuex";
export default {
  components: {
    UserProfile,
  },
  data: () => ({
    show1: false,
    show2: false,
    errorMessage: "",
    loading: false,
    valid: true,
    formData: {
      name: null,
      email: null,
      phone: null,
      password: null,
      // confirmPassword: null,
      country: null,
      checkbox: true,
    },
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 15 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phoneRules: [
      (v) => !!v || "Phone Number is Required",
      (v) => /.+@.+\..+/.test(v) || "Must be a valid Number",
    ],
    passwordRules: [
      (v) => v.length >= 8 || "Min 8 characters",
      (v) => !!v || "Password is Required",
    ],
    passwordConfirmRules: [
      (v) => v.length == this.formData.password || "Password does not match",
    ],

    countries: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua &amp; Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bosnia &amp; Herzegovina",
      "Botswana",
      "Brazil",
      "British Virgin Islands",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Cape Verde",
      "Cayman Islands",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Congo",
      "Cook Islands",
      "Costa Rica",
      "Cote D Ivoire",
      "Croatia",
      "Cruise Ship",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Polynesia",
      "French West Indies",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guam",
      "Guatemala",
      "Guernsey",
      "Guinea",
      "Guinea Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jersey",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kuwait",
      "Kyrgyz Republic",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Pierre &amp; Miquelon",
      "Samoa",
      "San Marino",
      "Satellite",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "South Africa",
      "South Korea",
      "Spain",
      "Sri Lanka",
      "St Kitts &amp; Nevis",
      "St Lucia",
      "St Vincent",
      "St. Lucia",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      `Timor L'Este`,
      "Togo",
      "Tonga",
      "Trinidad &amp; Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks &amp; Caicos",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Venezuela",
      "Vietnam",
      "Virgin Islands (US)",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
  }),
computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    submit() {
      console.log(this.formData);
    },
    async signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        await auth.signInWithPopup(provider);
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async createUser() {
      this.loading = true;
      this.errorMessage = "";
      try {
        if (this.formData.email && this.formData.password) {
          await auth.createUserWithEmailAndPassword(
            this.formData.email,
            this.formData.password
          );
          await db.collection("users").doc(auth.currentUser.uid).set({
            name: this.formData.name,
            phone: this.formData.phone,
            country: this.formData.country,
            newsletter: this.formData.checkbox,
          });

          this.color = "green";
          this.errorMessage = "Register Successfully";
          this.$router.replace("/");
        } else {
          this.color = "red";
          this.errorMessage = "Some Fields are Empty";
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  text-align: center;
}
</style>
