<template>
  <v-row>
    <v-row>
      <v-card class="my-3 ml-4" outlined :disabled="currentObject.customSize">
        <v-list>
          <v-list-item>
            <v-card-title
              class="text-overline py-0 pl-0 text-uppercase font-weight-bold"
              >Customized Size</v-card-title
            >
            <v-spacer></v-spacer>
            <!-- feet and inches shifter -->
            <!-- <v-card-title c class="pl-0 text-capitalize py-0">
              <v-radio-group v-model="customSize.unit" row dense>
                <v-radio color="primary" label="cm" value="cm"></v-radio>
                <v-radio color="primary" label="Feet & Inches" value="in" ></v-radio>
              </v-radio-group>
            </v-card-title> -->
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-form ref="form" lazy-validation>
                <!-- <v-container fluid class="pa-0"> -->
                

                  <!-- centimeter inputs -->
                  <v-row key="2" class="pa-0">
                    <v-col cols="6" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="customSize.width"
                        flat
                        label="Width"
                        required
                        outlined
                        dense
                        suffix="cm"
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" xs="6" sm="6" md="6" lg="6">
                      <v-text-field
                        v-model="customSize.height"
                        label="Height"
                        required
                        outlined
                        dense
                        max="5"
                        suffix="cm"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                <!-- </v-container> -->
              </v-form>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-text-field
          v-model="customSize.quanitty"
          type="number"
          label="Quantity"
          min="1"
          max="25"
          required
          outlined
          dense
          :suffix="(customSize.quanitty>1)?'Pcs':'Pc'"
          class="ml-1"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="">
        <v-btn
          block
          color="primary white--text  "
          depressed
          class="rounded-2 ml-n2"
          height="40"
           :loading="loading"
          @click="checkEmail()"
        >
          <v-icon left> mdi-email-send-outline </v-icon>
          Get Quote
        </v-btn>
      </v-col>

      <!-- <v-col cols="3"
        ><v-btn
          v-if="isFavorite"
          block
          depressed
          outlined
          color="success"
          height="40"
          class="ml-n2 ml-lg-n5 ml-md-1 rounded-2"
          @click="favoriteToggle"
        >
          <v-icon left> mdi-check-decagram </v-icon>

          Added 
        </v-btn>

        <v-btn
          v-else
          block
          depressed
          height="40"
          color="primary"
          class="ml-n3 ml-md-n3 ml-sm-n5 ml-lg-n5 rounded-2"
          @click="favoriteToggle"
        >
          <v-icon left> mdi-heart-plus-outline </v-icon>
          Favourite
        </v-btn>
      </v-col> -->
      <v-snackbar
        v-model="snackbar"
        top
        right
        transition="slide-y-reverse-transition"
        color="info"
        elevation="8"
        tile
        min-height="50"
      >
        {{ productDetail.title }}
        <span v-if="isFavorite"> is Added to Your favorites </span>
        <span v-else> is Removed From Your favorites </span>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close-circle-outline </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
    <!-------Dailog ----->
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title class="title">
            {{ messageHeader }}
          </v-card-title>

          <v-card-text>
            {{ message }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text to="/login" v-if="login">
              Login
            </v-btn>

            <v-btn color="black darken-1" text to="/register" v-if="login">
              Register
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-row>
</template>

<script>
import { createPdf } from "../utils/createPdf.js";
export default {
  name: "CustomSizeForm",
  props: ["productDetail", "user", "currentObject", "disabled"],

  data() {
    return {
      loading:false,
      isFavorite: false,
      customSize: {
        unit: "cm",
        height: "",
        width: "",
        quanitty: "1",
      },
      message: "",
      messageHeader: "",
      login: false,
      snackbar: false,
      dialog: false,
    };
  },
  methods: {
    favoriteToggle: function () {
      this.snackbar = true;
      this.isFavorite = !this.isFavorite;
    },
    checkEmail() {
      
      // this.loading= true
      if (!this.user.loggedIn) {
        this.messageHeader = "Login/Register to Make a Qoute";
        this.message =
          "Please Register if you don't have account yet or Login to submit your Quote.";
        this.dialog = true;
        this.login = true;
      } else if (!this.user.data.email) {
        this.messageHeader = "Email Required!";
        this.message = "Please update you email to submit your Quote";
        this.dialog = true;
      } else {
        if (!this.currentObject.customSize) {
          if (
            this.customSize.width >=1 &&
            this.customSize.height >=1
          ) {
            this.currentObject.Size =
              "Custom Size " +
              this.customSize.width +
              "X" +
              this.customSize.height;
          } else {
            this.messageHeader = "Size Required!";
            this.message = "Height or Width is not correct"
            this.dialog = true;
            return;
          }
        }
      
        let test = createPdf(
          this.currentObject,
          this.productDetail,
          this.customSize.unit,
          this.user.data,
          this.customSize.quanitty
        );
       
        this.customSize.height = null;
        this.customSize.width = null;
        this.message = test
        this.dialog = true
     
      }
        this.loading= false
    },
  },
};
</script>
