<template>
  <v-container class="login-form">
    <v-row justify="center" style="height: 90vh;" align="center">
      <v-col cols="12" sm="6" md="6" lg="4" xl="3" v-if="!user.loggedIn">
        <v-card class="pa-5 elevation-2" flat>
          <h1 class="font-weight-medium mb-4">Login</h1>
          <v-text-field
            :rules="emailRules"
            v-model="email"
            label="E-mail"
            required
            outlined
            large
            append-icon="mdi-email"
            class="mt-3"
          ></v-text-field>
          <v-text-field
            :rules="passwordRules"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-2"
            label="Password"
            hint="At least 8 characters"
            v-model="password"
            required
            outlined
            large
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn
            block
            type="submit"
            color="primary"
            large
            depressed
            :loading="loading"
            @click="signInUser()"
          >
            Login
          </v-btn>

          <v-btn
            block
            class="mt-2"
            type="submit"
            color="red darken-4 white--text"
            large
            depressed
            @click.prevent="signInWithGoogle()"
          >
            <v-icon left dark>
              mdi-google
            </v-icon>
            Login with Google
          </v-btn>
          <!-- <v-btn
            block
            class="mt-2"
            type="submit"
            color="orange darken-4 white--text"
            large
            depressed
          >
            <v-icon left dark>
              mdi-phone
            </v-icon>
            Login with OTP
          </v-btn> -->
          <!-- <div id="recaptcha-container"></div> -->
          <v-alert :color="color" dense text v-if="errorMessage" class="mt-2">
            {{ errorMessage }}
          </v-alert>
          <p class="mt-6">
            Need an Account? Click here to
            <router-link
              to="/register"
              class="font-weight-bold"
              style="text-decoration: none;"
            >
              Register
            </router-link>
          </p>
        </v-card>
      </v-col>
      <!-- <User :user="user.data"/> -->
      <!-- <UserProfile :user="user.data" v-if="user.loggedIn"/> -->
      <v-dialog v-model="dialog" width="350" transition="dialog-top-transition">
        <v-card>
          <v-card-title class="px-5">
            {{ message }}

            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-row>
    
  </v-container>
</template>

<script>
import { auth } from "@/utils/firebase";
// import UserProfile from "@/components/UserProfile";
// import User from "@/components/User";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
export default {
  name: "LoginForm",
 
  data() {
    return {
      show1: false,
      auth,
      color: "red",
      dialog: false,
      eamil: "",
      password: "",
      loading: false,
      errorMessage: "",
      message:'',
      passwordRules: [
        (v) => v.length >= 8 || "Min 8 characters",
        (v) => !!v || "Password is Required",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    async signInUser() {
      this.loading = true;
      this.errorMessage = "";

      try {
        if(this.email && this.password){
        await auth.signInWithEmailAndPassword(this.email, this.password);

    
        this.message = "Login Successfully";
        this.$router.go(-1)
            
        }
        this.errorMessage = 'Email or Password is not Valid'

      } catch (error) {
        this.errorMessage = error.message;
      }
      this.loading = false;
    },

    async signInWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        await auth.signInWithPopup(provider);
        this.$router.go(-1)
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  text-align: center;
}
</style>
