<template>
  <v-container justify-center>
  
      <v-row>
       <strong> Logged in As</strong>  {{ user.data.email }} 
        <!-- <strong>{{ user.data.uid }}</strong> -->
      </v-row>
      <v-row>
        <v-col cols="3">
          <!-- <v-btn
            class="white--text mr-4"
            color="red darken-4"
            large
            depressed
            :loading="loading"
            block
            @click="delUser(user)"
          >
            Delete Account</v-btn
          > -->
          </v-col
        >
        <v-col cols="3">
          <v-btn
            class="black white--text mr-4"
            large
            depressed
            :loading="loading"
            @click="signOut()"
            block
          >
            Sign Out
          </v-btn>
        </v-col>
      </v-row>
    
  </v-container>
</template>

<script>
import { auth } from "@/utils/firebase";
import { mapGetters } from "vuex";
export default {
  props: ["user"],
  data() {
    return {
      auth,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    delUser(user) {
      user
        .delete()
        .then(function () {
          alert(user + "Deleted");
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
    async signOut() {
      this.loading = true;
      await this.auth.signOut().then(() => {
        this.$router.push("/");
        console.log("User Signed Out");
      });
      this.loading = false;
    },
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Profile',
      // all titles will be injected into this template
      titleTemplate: '%s | Ramsha Carpets'
    }
};
</script>
