<template>
  <v-footer dark color="black" class="d-flex justify-space-between">
      <v-col cols="6" class="grey--text caption font-weight-light">Developed By Aamir Ansari</v-col>
      <v-col cols="6" class="grey--text caption font-weight-light text-right">Copyright Ⓒ{{ new Date().getFullYear() }} Ramsha Carpets. All Rights Reserved</v-col>
  </v-footer>
</template>

<script>
export default {
  name: "foot",

}
</script>

