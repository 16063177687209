<template>
  <v-app-bar app flat height="70" dark color="black" style="z-index: 999;">
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          alt="Ramsha Carpets"
          class="shrink"
          contain
          min-width="80"
          src="/img/RamshaCarpetswhite.png"
          width="150"
      /></router-link>
    </div>
    <v-spacer></v-spacer>

    <!-- Middle Menu Start Here -->
    <v-btn-toggle
      v-for="menu in menus"
      :key="menu.text"
      borderless
      background-color="transparent"
    >
      <v-btn router :to="menu.route" small plain>
        <span class="mr-1">{{ menu.text }}</span>
      </v-btn>
    </v-btn-toggle>
    <!-- Middle Menu End Here -->

    <!-- Left Menus after login starts here -->
    <v-spacer></v-spacer>
    <v-btn v-if="!user.loggedIn" to="/login" target="_blank" small plain>
      <span class="mr-1">Login/Register</span>
    </v-btn>
    <!-- Left Menus after login End  here -->

    <!-- Dropdown Starts Here -->

    <template v-else>
      <div class="text-center">
        <v-menu
          open-on-hover
          bottom
          class="mt-2"
          offset-y
          transition="scroll-x-reverse-transition"
          :loading="loading"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#272727" plain v-bind="attrs" v-on="on">
              <v-badge bordered dot color="pink" overlap>
                <v-avatar
                  color="teal darken-4 white--text"
                  size="42"
                  v-if="user.data.photo"
                >
                  <img :src="user.data.photo" :alt="user.data.displayName" />
                </v-avatar>
                <v-chip style="color: white;" v-else>
                  {{ user.data.email }}</v-chip
                >
              </v-badge>
            </v-btn>
          </template>

          <v-card class="mx-auto" max-width="500">
            <v-list>
              <v-list-item-group v-model="model" flat>
                <v-list-item
                  v-for="(item, i) in userMenu"
                  :key="i"
                  @click="item.event"
                  :to="item.link"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content :to="item.link">
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </template>
    <!-- Dropdown Ends Here -->

    <!------------dialog---------------->
    <v-dialog v-model="dialog" width="350" transition="dialog-top-transition">
      <v-card>
        <v-card-title class="px-5">
          {{ errorMessage }}

          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { auth } from "../utils/firebase";
import { mapGetters } from "vuex";
export default {
  name: "navbar",

  data() {
    return {
      dialog: false,
      auth,

      loading: false,
      errorMessage: "",
      menus: [
        // {
        //   text: "Home",
        //   route: "/",
        // },
        // {
        //   text: "About",
        //   route: "/about",
        // },
        // {
        //   text: "Infrastructure",
        //   route: "/infrastructure",
        // },
        // {
        //   text: "Products View",
        //   route: "/product_view",
        // },
        // {
        //   text: "Add Products",
        //   route: "/create_products",
        // },
        // {
        //   text: "Products List",
        //   route: "/product_list",
        // },
        // {
        //   text: "Products ",
        //   route: "/products",
        // },
        // {
        //   text: "Contact",
        //   route: "/contact",
        // },
        // {
        //   text: "F.A.Q",
        //   route: "/faq",
        // },
      ],
      userMenu: [
        // {
        //   icon: "mdi-account",
        //   text: "Profile",
        //   link: "/user",
        // },

        // {
        //   icon: "mdi-heart",
        //   text: "Favourites",
        //   link: "",
        // },
        {
          icon: "mdi-logout-variant",
          text: "Logout",
          event: this.signOut,
        },
      ],
      model: 0,
    };
  },
  methods: {
    signOut() {
      this.auth
        .signOut()
        .then(() => {
          this.errorMessage = "Logout Succesfully";
          this.dialog = true;
          this.$router.push("/login");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
