<template>
  <v-app>
    <NavBar  />
    <v-main>
      <transition name="scroll-x-transition">
      <router-view />
      </transition>
    </v-main>

    <Foot v-if="1" class="mt-10" />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Foot from "@/components/Foot.vue";

// import { ref } from "@vue/composition-api";
export default {
  name: "App",

  components: {
    NavBar,
    Foot,
  },


 
};
</script>
<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap');
* {
  font-family: "Jost";
  font-weight: 400;
}
h2{
  font-family: 'Jost', sans-serif;
  font-size: 30px;
  font-weight: 500;
}
</style>