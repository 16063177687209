<template>
  <v-container  v-if="loading">
    <v-row class="loaderHeight" >
      <v-col cols="2" align-self="center" offset="5" >
        <!-- <v-progress-circular
          size="40"
          width="3"
          class="mx-auto"
          color="black"
          indeterminate
        ></v-progress-circular> -->
        <v-progress-linear
          max-width="150"
          color="black"
          height="6"
          indeterminate
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="mt-12" style="max-width: 1320px;">
    <v-row class="px-2"> 
     <v-col cols="5"> 
       <v-btn depressed  color="transparent" to="/"  ><v-icon left dark>mdi-chevron-left</v-icon>
         Back
      </v-btn>
    </v-col>
    <v-col cols="7">
      <h2 class="font-bold ">{{category}}</h2>
    </v-col>
    </v-row>
    <v-divider class="my-6"></v-divider>
    <v-row>
      <v-col
        cols="6"
        sm="4"
        md="4"
        :lg='grid'
        :xl=grid
        v-for="(product, index) in allDesigns"
        :key="index"
      >
        <v-lazy loading transition="fade-transition">
          <v-hover>
            <template v-slot:default="{ hover }">
          <v-card
            :elevation="hover ? 12 : 0"
            :to="{
              name: 'Product View',
              params: { id: product.deasignName,category:product.productType },
            }"
            
            :loading="loading"
            class="my-3 py-1 rounded-5 transition-swing "
            max-width="420"
          >
            <template slot="progress">
              <v-progress-linear
                color="black"
                height="5"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              width="95%"
              class="mb-7 pt-2 mx-auto align-center text-center"
              lazy-src="/img/lazy.jpg"
              :src="product.thumb"
              transition="fade-transition"
            >

                <v-btn color="white" class="hide transition-swing px-8 py-10" tile :class="{'show':hover}">
                  <v-icon left >mdi-square-edit-outline</v-icon>
                  Customize
                </v-btn>
            </v-img>
              <v-divider class="pt-7 mt-n4" />
                <v-card-title class="mt-n8">{{ product.deasignName }}</v-card-title>
                <v-card-subtitle class="mt-n5 mb-2">{{product.productType}}</v-card-subtitle>
          </v-card>
          </template>
           </v-hover>
           
        </v-lazy>
      </v-col>
     
    </v-row>
    <v-row justify="center" class="my-5">
      <v-btn
        color="white my-8 py-7 px-9 text-capitalize"
        tile
        :loading="load"
        v-if="dis"
        @click="nextPage">
        
        <v-icon left dark>mdi-reload</v-icon>
        More Designs 
      </v-btn>
      
      <p v-else>No More Products</p>
      <!-- <v-subheader>Offset Top</v-subheader>
      {{ scrollPosition }} -->
    </v-row>
  </v-container>
</template>
<script>
import { db } from "@/utils/firebase";
export default {
  data() {
    return {
      grid:3,
      dis: true,
      load: false,
      loading: false,
      allDesigns: [],
      pageSize: 8,
      field: "products",
      category: this.$route.params.id,
      lastVisible: "",
      orderBy: "deasignName",
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      this.ti = this.$route.params.id;
      this.loading = true;
      await db
        .collection(this.field)
        .where("productType", "==", this.category)
        // .orderBy(this.orderBy)
        .limit(this.pageSize)
        .get()
        .then((querySnapshot) => {
          let docs = querySnapshot.docs;
          for (let doc of docs) {
            if (doc.data().visible) this.allDesigns.push(doc.data());
          }
          this.lastVisible = docs[docs.length - 1];
          this.loading = false;
        });
    },
    async nextPage() {
      this.load = true;
      await db
        .collection(this.field)
        // .orderBy(this.orderBy)
        .where("productType", "==", this.category)
        .startAfter(this.lastVisible)
       
        .get()
        .then((querySnapshot) => {
          let docs = querySnapshot.docs;
          for (let doc of docs) {
            if (doc.data().visible){ 
                this.allDesigns.push(doc.data())
              
                };
          
          }
          this.lastVisible = docs[docs.length - 1];
          if (docs.length == 0) {
            this.dis = false;
          }
        });
        
      this.load = false;
      
    },
   
  },
};
</script>
<style lang="css">

  .hide{
    opacity: 0;
  }
  .show{
    opacity: 1;
}
.loaderHeight{
  height: calc(100vh - 200px);
  text-align: center;
}
</style>
