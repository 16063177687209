import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCf4AWh7stQA56K3RoSjPm6jLsCjRwATt0',
//   authDomain: 'test-c115c.firebaseapp.com',
//   projectId: 'test-c115c',
//   storageBucket: 'test-c115c.appspot.com',
//   messagingSenderId: '721280915270',
//   appId: '1:721280915270:web:e35aa4445987e25fee3747',
//   measurementId: 'G-YZ0SLE5NFF',
// };

var firebaseConfig = {
    apiKey: "AIzaSyDI0k02QkgYMyT4ydotao_B0TE6saN_zXw",
    authDomain: "ramsha-novara.firebaseapp.com",
    projectId: "ramsha-novara",
    storageBucket: "ramsha-novara.appspot.com",
    messagingSenderId: "831804806440",
    appId: "1:831804806440:web:ce72312c57ca29926cac1d",
    measurementId: "G-5ESXJL7R99"
  };
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

