import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { firestorePlugin } from "vuefire";
import { auth } from "@/utils/firebase";
import { jsPDF } from "jspdf";
import VueMeta from "vue-meta";
import * as htmlToImage from "html-to-image";

Vue.use(firestorePlugin);
Vue.use(jsPDF);
Vue.use(htmlToImage);

Vue.config.productionTip = false;
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});
auth.onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
